<template>
    <a-layout-content class="Dashboard">
        <a-breadcrumb style="margin: 16px 24px">
            <a-breadcrumb-item>
                <router-link :to="{ path: '/' }" class="nav-link">
                    Trang chủ
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="Dashboard" :style="{minHeight: 'calc(100vh - 120px)', backgroundColor: '#fff', margin: '15px 25px', padding: '15px' }">
            <h1>{{ this.$route.name }}</h1>
            <a-divider/>
            <div class="report">
                <div class="report__filter">
                    <div class="filter-item">
                        <span><a-icon type="clock-circle" /> Thời gian: </span>
                        <a-range-picker
                                :locale="locale"
                                :defaultValue="[startDate, currentDate]"
                                @change="onChange"
                                format="YYYYMMDD"
                        />

                        <a-select :showArrow="false" showSearch :filterOption="filterOption" placeholder="Chọn salon" style="min-width: 120px; max-width: 500px; margin-left: 20px" @change="onSelectSalonChange">
                            <a-select-option v-for="item in getSalons" :key="item.id" :value="item.id">{{ item.shortName }}</a-select-option>
                        </a-select>

                        <a-select v-model="staffSelectModel" :showArrow="false" :loading="staffSelectLoading" showSearch :filterOption="filterOption" placeholder="Chọn nhân sự" style="min-width: 200px; max-width: 500px; margin-left: 20px" @change="onSelectStaffChange">
                            <a-select-option v-for="item in staffs" :key="item.id" :value="item.id">{{ `${item.id} - ${item.name}` }}</a-select-option>
                        </a-select>

                        <a-button style="margin-left: 20px" type="primary" @click="handleGetReport" icon="export">Xem dữ liệu</a-button>
                    </div>
                </div>
                <div class="header__action">
                    <export-excel class="btn btn-default" :data="data" :fields="excelFields" worksheet ="Báo cáo tỉ lệ trả lời đúng theo câu hỏi" :name="getFileXlsName">
                        <a-button style="margin-left: 20px" type="primary" icon="export">Xuất file Excel</a-button>
                    </export-excel>
                </div>
            </div>
            <a-divider/>
            <a-table :columns="columns" :dataSource="data" size="middle" bordered :rowKey="(record, index) => index" :loading="loading" :pagination="pagination ">
                <div
                        slot="filterDropdown"
                        slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                        style="padding: 8px"
                >
                    <a-input
                            v-ant-ref="c => searchInput = c"
                            :placeholder="`Tìm kiếm ${column.title}`"
                            :value="selectedKeys[0]"
                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                            @pressEnter="() => handleSearch(selectedKeys, confirm)"
                            style="width: 188px; margin-bottom: 8px; display: block;"
                    />
                    <a-button type="primary" @click="() => handleSearch(selectedKeys, confirm)" size="small" style="width: 90px; margin-right: 8px">Tìm kiếm</a-button>
                    <a-button @click="() => handleReset(clearFilters)" size="small" style="width: 90px">Reset</a-button>
                </div>
                <a-icon
                        slot="filterIcon"
                        slot-scope="filtered"
                        type="search"
                        :style="{ color: filtered ? '#108ee9' : undefined, fontWeight: filtered ? 'bold': undefined }"
                />
                <template slot="action" slot-scope="text, record">
                    <div style="text-align: center" :key="record.id">
                        <a-button @click="openDrawer({questionId: record.questionId})" type="primary">Xem chi tiết</a-button>
                    </div>
                </template>
                <template slot="correctPercent" slot-scope="text, record">
                    <div :key="record.id">
                        <a-tag>{{text}}%</a-tag>
                    </div>
                </template>
                <template slot-scope="text">
                    <div :key="col">
                        <template>{{text}}</template>
                    </div>
                </template>
            </a-table>
        </div>

        <a-drawer
                :width="800"
                closable
                :visible="visible"
                @close="onClose"
                :wrapStyle="{height: 'calc(100% - 108px)',overflow: 'auto',paddingBottom: '108px'}"
        >
            <div class="table_header">
                <h1>Chi tiết các câu trả lời</h1>
                <div v-if="recordSelected" class="header_info">
                    <span v-if="recordSelected">Nhân sự: <strong>{{recordSelected.id}} - {{recordSelected.name}}</strong></span>
                </div>
            </div>
            <a-table :columns="detailColumns" :dataSource="details" size="middle" bordered :rowKey="(record, index) => index" :loading="loading" :pagination="false">
                <template slot="correct" slot-scope="text, record">
                    <div style="text-align: center" :key="record.question">
                        <a-checkbox :checked="record.correct" />
                    </div>
                </template>
                <template slot="answers" slot-scope="text, record">
                    <div :key="record.questionId + record.workDateString">
                        <ul style="padding-left: 20px">
                            <li v-for="(answer, index) in parseAnwers(record.answerContent)" :key="index" >{{ answer }}</li>
                        </ul>
                    </div>
                </template>
                <template slot-scope="text">
                    <div :key="col">
                        <template>{{text}}</template>
                    </div>
                </template>
            </a-table>
        </a-drawer>
    </a-layout-content>
</template>

<script>
    import moment from 'moment'
    import locale from '../../configs/datePickerLocale'
    import { mapGetters, mapActions } from 'vuex';
    import { RepositoryFactory } from "@/repository/RepositoryFactory";
    const reportRepository = RepositoryFactory.get('report');
    const salonRepository = RepositoryFactory.get('salon');
    moment.locale("vi-vn")

    export default {
        name: "QuestionContentReport",
        data() {
            return {
                visible: false,
                searchText: '',
                searchInput: null,
                locale,
                startDate: moment().startOf('month'),
                currentDate: moment(),
                salonId: 0,
                data: [],
                staffs: [],
                staffSelectModel: undefined,
                staffSelectLoading: false,
                staffIdSelected: undefined,
                loading: false,
                pagination: {
                    pageSize: 15,
                    showTotal: (total, range) => `Hiển thị từ ${range[0]} - ${range[1]} / ${total} dòng`
                },
                excelFields: {
                    'ID câu hỏi': 'questionId',
                    'Nội dung câu hỏi': 'questionContent',
                    'Nhóm câu hỏi': 'questionGroup',
                    'Tổng lượt thực hiện': 'total',
                    'Tổng lượt trả lời đúng': 'totalCorrect',
                    'Tỉ lệ trả lời đúng': {
                        field: 'correctPercent',
                        callback: (value) => {
                            return `${value}%`
                        }
                    }
                },
                columns: [
                    {
                        title: 'Id câu hỏi',
                        dataIndex: 'questionId',
                        key: 'questionId',
                        width: '7%',
                        scopedSlots: {
                            filterDropdown: 'filterDropdown',
                            filterIcon: 'filterIcon'
                        },
                        onFilter: (value, record) => record.questionId.toString().toLowerCase().includes(value.toLowerCase()),
                        onFilterDropdownVisibleChange: visible => {
                            if (visible) {
                                setTimeout(() => {
                                    this.searchInput.focus();
                                }, 0);
                            }
                        },
                    },
                    {
                        title: 'Nội dung câu hỏi',
                        dataIndex: 'questionContent',
                        key: 'questionContent',
                        width: '40%',
                    },
                    {
                        title: 'Nhóm câu hỏi',
                        dataIndex: 'questionGroup',
                        key: 'questionGroup',
                        width: '15%'
                    },
                    {
                        title: 'Tổng lượt thực hiện',
                        dataIndex: 'total',
                        key: 'total',
                        width: '10%',
                    },
                    {
                        title: 'Tổng lượt trả lời đúng',
                        dataIndex: 'totalCorrect',
                        key: 'totalCorrect',
                        width: '10%',
                    },
                    {
                        title: 'Tỉ lệ trả lời đúng',
                        dataIndex: 'correctPercent',
                        key: 'correctPercent',
                        width: '10%',
                        scopedSlots: { customRender: 'correctPercent' },
                        sorter: (a, b) => { return a.correctPercent - b.correctPercent}
                    },
                    {
                        title: 'Hành động',
                        key: 'action',
                        width: '20%',
                        scopedSlots: { customRender: 'action' },
                    }
                ],
                recordSelected: null,
                details: [],
                detailColumns: [
                    {
                        title: 'STT',
                        dataIndex: 'incr',
                        key: 'incr',
                        width: '5%'
                    },
                    {
                        title: 'Câu hỏi',
                        dataIndex: 'questionContent',
                        key: 'questionContent',
                        width: '30%'
                    },
                    {
                        title: 'Câu trả lời',
                        dataIndex: 'answerContent',
                        key: 'answerContent',
                        scopedSlots: { customRender: 'answers' },
                        width: '30%'
                    },
                    {
                        title: 'Kết quả',
                        dataIndex: 'correct',
                        key: 'correct',
                        scopedSlots: { customRender: 'correct' },
                        width: '10%'
                    },
                    {
                        title: 'Nhóm câu hỏi',
                        dataIndex: 'questionGroup',
                        key: 'questionGroup',
                        width: '10%'
                    },
                    {
                        title: 'Ngày thực hiện',
                        dataIndex: 'workDateString',
                        key: 'workDateString',
                        width: '20%'
                    }
                ]
            }
        },
        computed: {
            ...mapGetters('staticData', [
                'getSalons'
            ]),
            getFileXlsName() {
                return "bao-cao-tile-traloi-dung-theo-cau-hoi-" + this.staffIdSelected + ".xls"
            }
        },
        methods: {
            ...mapActions('authenticate',[
                'refreshToken'
            ]),
            moment,
            onChange(date) {
                this.currentDate = date
            },
            async handleGetReport() {
                if (!this.staffSelectModel) {
                    await this.$message.error('Bạn phải chọn một nhân sự để xem báo cáo!');
                    return;
                }

                try {
                    this.loading = true;
                    let response = await reportRepository.questionContentReport({
                        workDateStart: this.startDate.format("YYYYMMDD"),
                        workDateEnd: this.currentDate.format("YYYYMMDD"),
                        staffId: this.staffIdSelected
                    });

                    if (response.data.meta.success) {
                        this.data = response.data.data.map( item => {
                            return {...item, correctPercent:  Math.round(item.totalCorrect / item.total * 100)}
                        });
                    }
                } catch (e) {
                    if (e.message.includes("401")) {
                        await this.refreshToken();
                        this.handleGetReport();
                    } else {
                        this.openNotification(e.message)
                    }
                } finally {
                    this.loading = false;
                }
            },
            filterOption(inputValue, options) {
                return options.componentOptions.children[0].text.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            },
            async onSelectSalonChange(value) {
                try {
                    this.staffSelectLoading = true;
                    let response = await salonRepository.getAllStaffInSalon({salonId: value});

                    if (response.data.meta.success) {
                        this.staffs = response.data.data
                    }
                } catch (e) {
                    this.openNotification(e.message)
                } finally {
                    this.staffSelectLoading = false;
                    this.staffSelectModel = undefined;
                }
            },
            onSelectStaffChange(value) {
                this.staffIdSelected = value;
                this.recordSelected = this.staffs.filter(staff => staff.id === value)[0];
            },
            openNotification (message) {
                this.$notification.open({
                    message: message
                });
            },
            handleSearch(selectedKeys, confirm) {
                confirm();
                this.searchText = selectedKeys[0];
            },

            handleReset(clearFilters) {
                clearFilters();
                this.searchText = '';
            },
            async openDrawer({questionId}) {
                try {
                    this.loading = true;
                    let response = await reportRepository.questionContentDetail({
                        staffId: this.staffIdSelected,
                        questionId,
                        workDateStart: this.startDate.format("YYYYMMDD"),
                        workDateEnd: this.currentDate.format("YYYYMMDD"),
                    });

                    if (response.data.meta.success) {
                        this.details = response.data.data.map( (item,index) => {
                            return {...item, incr: index + 1}
                        });
                        this.visible = true;
                    }
                } catch (e) {
                    this.openNotification(e.message)
                } finally {
                    this.loading = false;
                }
            },
            onClose() {
                this.visible = false;
            },
            parseAnwers(answers) {
                if (!answers) {
                    return []
                }
                return answers.split("\n")
            }
        }
    }
</script>

<style scoped>
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .header__action {
        display: flex;
        flex-direction: row;
    }
    .filter-item span {
        font-weight: bold;
    }
    .report {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .table_header, .header_info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .header_info span {
        margin: 10px    ;
    }

</style>